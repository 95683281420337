import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import { Box, Button, ButtonProps, Collapse, HStack, Text, useDisclosure } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5'

export interface SidebarAccordionProps {
  children: JSX.Element
  expanded?: boolean
  icon?: JSX.Element
  label: string
  rootProps?: Omit<ButtonProps, 'children'>
}

export const SidebarAccordion = ({ children, expanded, icon, label, rootProps }: SidebarAccordionProps) => {
  const { isOpen, onToggle } = useDisclosure()
  const { formatMessage } = useFormat({ name: 'common' })
  return (
    <>
      <Button
        aria-label={formatMessage({ id: 'account.dashboard.title' })}
        aria-expanded={isOpen}
        borderBottom={{ base: '1px solid #E2E2E2', md: 'none' }}
        borderRadius={'none'}
        display={'flex'}
        height={'48px'}
        justifyContent={'space-between'}
        onClick={onToggle}
        p={'16px'}
        variant={'unstyled'}
        {...rootProps}
      >
        <HStack spacing={'8px'} width={'full'} aria-hidden>
          {icon}
          <Text textStyle={'callouts-blockquote-100'}>{label}</Text>
        </HStack>
        {isOpen ? (
          <MinusIcon aria-hidden fontSize={16} color={'text-primary'} />
        ) : (
          <AddIcon aria-hidden fontSize={16} color={'text-primary'} />
        )}
      </Button>

      <Collapse in={expanded ? !isOpen : isOpen} animateOpacity>
        <Box padding={'16px'}>{children}</Box>
      </Collapse>
    </>
  )
}

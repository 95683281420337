import { Text, VStack } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { SidebarLogout } from './sidebar-logout'
import { SidebarNavContactUs, ContactUsItem } from './sidebar-nav-contact-us'
import { SideBarNavMenu, MenuItem } from './sidebar-nav-menu'

interface SidebarNavProps {
  activeItem?: ContactUsItem | MenuItem
  logout: () => void
  size: 'Small' | 'Large'
  state?: 'Expanded' | 'Collapsed'
}

export const SidebarNav = ({ activeItem, logout, size, state = 'Expanded' }: SidebarNavProps) => {
  const { formatMessage } = useFormat({ name: 'common' })

  if (state !== 'Expanded') return <></>

  return (
    <>
      {size === 'Small' && (
        <>
          <SideBarNavMenu activeItem={activeItem} />
          <SidebarNavContactUs activeItem={activeItem} />
          <SidebarLogout onClick={logout} />
        </>
      )}
      {size === 'Large' && (
        <>
          <Text textStyle={'heading-desktop-200'} height={'24px'}>
            {formatMessage({ id: 'account.dashboard.title' })}
          </Text>
          <VStack spacing={'0'} width={'full'} alignItems={'stretch'}>
            <SideBarNavMenu activeItem={activeItem} />
            <SidebarLogout onClick={logout} />
            <SidebarNavContactUs activeItem={activeItem} />
          </VStack>
        </>
      )}
    </>
  )
}

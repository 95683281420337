import { useRouter } from 'next/router'
import { DEFAULT_ACCOUNT_DASHBOARD_PATH } from 'composable/components/account/account-dashboard-nav'
import { useFormat } from 'helpers/hooks/useFormat'
import { IoHelpBuoyOutline, IoHelpCircleOutline, IoRefreshOutline } from 'react-icons/io5'
import { ContactUs } from './contact-us'
import { SidebarItem } from './sidebar-item'
import { useComposable } from '../composable-provider'

interface SidebarNavContactUsProps {
  activeItem?: ContactUsItem
}

export type ContactUsItem = {
  path: string
  icon: JSX.Element
  label: string
  intlId: string
}

export const contactUsItems = [
  {
    path: 'support',
    icon: <IoHelpBuoyOutline size={'24px'} />,
    label: 'Customer Support',
    intlId: 'account.dashboard.menu.customerSupport',
  },
  {
    path: 'shipping-and-return',
    icon: <IoRefreshOutline size={'24px'} />,
    label: 'Shipping & Return Policy',
    intlId: 'account.dashboard.menu.shippingAndReturn',
  },
  {
    path: 'faq',
    icon: <IoHelpCircleOutline size={'24px'} />,
    label: 'FAQs',
    intlId: 'account.dashboard.menu.faq',
  },
] as const

export const SidebarNavContactUs = ({ activeItem }: SidebarNavContactUsProps) => {
  const router = useRouter()
  const { accountDrawer } = useComposable()
  const { formatMessage } = useFormat({ name: 'common' })
  return (
    <ContactUs>
      {contactUsItems.map((item) => (
        <SidebarItem
          key={item.path}
          state={activeItem?.path === item.path ? 'Active' : 'Default'}
          href={`${DEFAULT_ACCOUNT_DASHBOARD_PATH}/${item.path}`}
          label={formatMessage({ id: item.intlId })}
          rootProps={{
            borderBottom: 'none',
            leftIcon: item.icon,
            padding: '0',
            onClick: () => {
              router.push(`${DEFAULT_ACCOUNT_DASHBOARD_PATH}/${item.path}`)
              accountDrawer.onClose()
            },
          }}
          textProps={{
            textStyle: {
              base: 'callouts-blockquote-75',
              md: 'callouts-blockquote-100',
            },
          }}
        />
      ))}
    </ContactUs>
  )
}

import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  Input,
  InputProps,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { useState } from 'react'
import { FieldError } from 'react-hook-form'
import { PiInfoFill } from 'react-icons/pi'

interface InputFieldProps {
  inputProps: InputProps
  label?: string
  callToAction?: JSX.Element
  error?: FieldError
  formLabelProps?: FormLabelProps
  isRequired?: boolean
  caption?: string
  showInfoIcon?: boolean
}

export const InputField = ({
  inputProps,
  label,
  callToAction,
  error,
  formLabelProps,
  isRequired = false,
  caption,
  showInfoIcon = false,
}: InputFieldProps) => {
  const intl = useFormat({ name: 'common' })
  const { name } = inputProps

  const [showTooltip, setShowTooltip] = useState(false)

  const handleTouchStart = () => {
    setShowTooltip(!showTooltip)
  }

  const handleTouchEnd = () => {
    setTimeout(() => setShowTooltip(false), 1000)
  }

  const handleMouseEnter = () => {
    setShowTooltip(true)
  }

  const handleMouseLeave = () => {
    setShowTooltip(false)
  }

  const tooltipLabelLine1 = intl.formatMessage({
    id: 'checkoout.address.tooltip.label1',
  })
  const tooltipLabelLine2 = intl.formatMessage({
    id: 'checkoout.address.tooltip.label2',
  })
  const tooltipLabelLine3 = intl.formatMessage({
    id: 'checkoout.address.tooltip.label3',
  })
  const tooltipLabels = [tooltipLabelLine1, tooltipLabelLine2, tooltipLabelLine3]

  if (!name) return null

  return (
    <FormControl isInvalid={Boolean(error)} isRequired={isRequired} display="flex" flexDirection="column">
      <Flex position="relative" alignItems="center" mb={2}>
        {label && (
          <FormLabel
            htmlFor={name}
            textStyle="heading-desktop-75"
            fontWeight="bold"
            requiredIndicator={<Text as="span">*</Text>}
            m={0}
            display="flex"
            alignItems="center"
          >
            {label}
          </FormLabel>
        )}
        {showInfoIcon && (
          <Tooltip
            label={tooltipLabels.map((label, index) => (
              <Text key={index}>{label}</Text>
            ))}
            placement="top"
            rounded="md"
            maxW={{ base: '90vw', sm: 'auto' }}
            mr={{ base: 2, sm: 0 }}
            hasArrow
            isOpen={showTooltip}
          >
            <Flex
              as="span"
              align="center"
              justify="center"
              cursor="pointer"
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              ml={2}
            >
              <PiInfoFill size="16px" />
            </Flex>
          </Tooltip>
        )}
        {callToAction}
      </Flex>

      <Input
        id={name}
        _placeholder={{
          color: 'shading.300',
        }}
        {...inputProps}
      />
      <FormErrorMessage mt={2}>{error?.message}</FormErrorMessage>
      {caption && (
        <FormHelperText fontSize="xs" color="text.muted">
          {caption}
        </FormHelperText>
      )}
    </FormControl>
  )
}
